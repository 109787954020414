// File: LoginPage.jsx
import React from 'react';
import './LoginPage.css'; // Add custom styles here for accurate replication

const LoginPage = () => {
  return (
    <div id="sds-login">
      <BackgroundVideo />
      <WelcomeInfo />
      <LoginDialog />
      <FooterLogos />
    </div>
  );
};

const BackgroundVideo = () => {
  return (
    <div id="sds-login-background">
      <video
        loop
        autoPlay
        muted
        src="https://cdn.gclite.org/videos/index.mp4"
        draggable="false"
      />
      <audio
        preload="auto"
        loop
        autoPlay
        src="https://cdn.gclite.org/audios/13.mp3"
        draggable="false"
      />
    </div>
  );
};

const WelcomeInfo = () => {
  return (
    <div className="sds-login-dark">
        <div className="sds-login-welcome-info">
        <div className="sds-login-cuslogo-wrapper">
            <img
            draggable="false"
            src="https://cdn.gclite.org/images/logo.svg"
            id="sds-login-cuslogo-img"
            width="72px"
            alt="Logo"
            />
        </div>
        <div className="sds-login-welcome-info-wrapper">
            <div className="sds-login-welcome-info-container">
            <div className="sds-login-welcome-info-title" title="Welcome To DreamX!">
                Welcome To DreamX!
            </div>
            </div>
            <div className="sds-login-welcome-info-container">
            <div className="sds-login-welcome-info-msg" title="We Have Been Expecting You">
                We Have Been Expecting You
            </div>
            </div>
        </div>
        </div>
    </div>
  );
};

const LoginDialog = () => {
  return (
    <div id="sds-login-dialog">
      <div id="sds-login-dialog-title" title="Dreamx">
        DreamX
      </div>
      <div id="sds-login-dialog-form" className="x-plain-bwrap">
        <form id="login-form" className="x-form syno-ux-formpanel-no-tbar" role="log">
          <div className="x-panel-bwrap">
            <div className="x-panel-body-noborder">
            <div className=" sds-icon-text-field ">
              <TextInput
                iconClass="user-icon"
                id="login_username"
                name="username"
                type="text"
                ariaLabel="Username"
              />
              </div>
              <div className=" sds-icon-text-field ">
              <TextInput
                iconClass="passwd-icon"
                id="login_passwd"
                name="passwd"
                type="password"
                ariaLabel="Password"
              />
              </div>
              <div className=" sds-icon-text-field x-hide-label x-hide-display">
              <TextInput
                iconClass="otp-icon"
                id="login_otp"
                name="OTPcode"
                type="text"
                ariaLabel="Enter 6-digit code"
                placeholder="Enter 6-digit code"
              />
              </div>
              <Checkbox id="rememberme" name="rememberme" label="Stay signed in" />
              <Button id="login-btn" text="Sign In" buttonClass="x-btn syno-ux-button syno-ux-button-blue x-btn-noicon" />
              <Button
                id="forget_pass"
                text="Forgot your password?"
                buttonClass="syno-ux-button-default x-hide-display"
              />
              <Button
                id="lost_phone"
                text="Lost your phone?"
                buttonClass="syno-ux-button-default x-hide-display"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const FooterLogos = () => {
  return (
    <div id="sds-login-logo" className="dark sds-logo">
      <div className="logo-synology"></div>
      <div className="logo-DSM"></div>
      <div className="logo-major"></div>
      <div className="logo-2"></div>
    </div>
  );
};

const TextInput = ({ iconClass, id, name, type, ariaLabel, placeholder }) => {
  return (
    <div className="sds-icon-text-field">
      <div className={`icon ${iconClass}`}></div>
      <input
        type={type}
        className="x-form-text x-form-field textfield"
        id={id}
        name={name}
        maxLength="256"
        aria-label={ariaLabel}
        placeholder={placeholder || ''}
      />
    </div>
  );
};

const Checkbox = ({ id, name, label }) => {
  return (
    <div className="form-checkbox" style={{ marginLeft: '20px', marginTop: '10px' }}>
      <input
        type="checkbox"
        role="checkbox"
        className="x-form-checkbox x-form-field"
        id={id}
        name={name}
      />
      <label htmlFor={id} className="syno-ux-checkbox-label">
        {label}
      </label>
    </div>
  );
};

const Button = ({ id, text, buttonClass }) => {
  return (
    <span id={id} className={`${buttonClass}`}>
      <em className=" x-unselectable">
        <button type="button" className="x-btn-text">
          {text}
        </button>
      </em>
    </span>
  );
};

export default LoginPage;
